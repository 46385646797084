<template>
  <v-app fill-height class="w-100 grey lighten-4">
    <main class="w-100 my-3 fill-height mx-auto">
      <v-container fluid fill-height>
        <section class="w-100 d-flex align-items-center justify-content-center fill-height">
          <div class="col-sm-6 col-12 rounded-lg shadow-sm d-flex flex-column white fill-height">
            <div class="align-self-end">
              <v-select v-model="selected" :items="languages" solo dense class="rounded-pill fit f12" hide-details>
              </v-select>
            </div>

            <div class="d-flex align-items-center justify-content-center">
              <img src="@/assets/images/app/mindology.png" width="40%" max-width="150px" />
            </div>

            <div class="mt-2 text-center">
              <span class="font-weight-bold text--black f16">
                {{ $t('It`s time to get to yourself!') }}
              </span>
            </div>

            <div class="mt-3 text-center">
              <span class="font-weight-normal grey--text text--darken-1 f15">
                {{ $t('In Mindology, the comprehensive platform of psychology') }}
                <br />
                {{ $t('Train and practice regularly to improve your quality of life') }}
              </span>
            </div>

            <div class="ms-0 me-2 mt-auto">
              <v-checkbox class="font-weight-light text--grey lighten-1" v-model="checkbox">
                <template #label>
                  <span class="f13">
                    {{ $t('Login or registration means accept') }}
                    <a @click.stop href="https://mindology.io/policy" target="_blank">
                      <span class="text--info">
                        {{ $t('terms and conditions') }}
                      </span>
                    </a>
                    {{ $t('and') }}
                    <a @click.stop href="https://mindology.io/privacy" target="_blank">
                      <span class="text-info">
                        {{ $t('privacy policy') }}

                      </span>
                    </a>
                    {{ $t('of Mindology.') }}
                  </span>
                </template>
              </v-checkbox>
            </div>

            <div class="mt-2 d-flex flex-row align-items-stretch">
              <v-btn color="primary" large class="flex-fill mx-2 13" @click="loginUser()">
                {{ $t('Client login') }}
              </v-btn>

              <v-btn color="primary" large class="flex-fill mx-2 f13" @click="loginDoctor()">
                {{ $t('Specialist login') }}
              </v-btn>
            </div>

            <span class="text-center d-block w-100 fw-bold f13 primary--text text--darken-3 mt-1">{{ $t('Version') }}
              {{ version }}</span>
          </div>
        </section>
      </v-container>
    </main>
  </v-app>
</template>
<script>
import { version } from '@/../package.json';
export default {
  data() {
    return {
      version,
      checkbox: false,

      selected: "fa",
      languages: [
        { value: "fa", text: "فارسی" },
        { value: "en", text: "Eng" },
      ],
    };
  },
  methods: {
    updateLocale(locale) {
      this.$i18n.locale = locale;
      this.$store.commit("update_locale", locale);
      (locale == "fa") ? this.$vuetify.rtl = true : this.$vuetify.rtl = false;
    },
    loginUser() {
      if (this.checkbox) {
        this.$store.commit("update_clientType", "CLIENT");
        this.$router.push("/login");
      } else {
        this.$swal({
          icon: "warning",
          title: this.$t('Terms and Conditions'),
          text: this.$t('You have not accepted the rules and regulations of Mindology'),
          confirmButtonText: this.$t('OK'),
        });
      }
    },
    loginDoctor() {
      if (this.checkbox) {
        this.$store.commit("update_clientType", "DOCTOR");
        this.$router.push("/login");
      } else {
        this.$swal({
          icon: "warning",
          title: this.$t('Terms and Conditions'),
          text: this.$t('You have not accepted the rules and regulations of Mindology'),
          confirmButtonText: this.$t('OK'),
        });
      }
    },
  },
  beforeMount() {
    if (this.$store.getters.isLoggedIn) {
      if (!this.$store.getters.authenticated) {
        if (this.$store.getters.clientType == "CLIENT") {
          this.$router.push("/register-client");
        }
        else if (this.$store.getters.clientType == "DOCTOR") {
          this.$router.push("/register-specialist");
        }
        else {
          this.$store.dispatch("logout");
        }
      } else if (this.$store.getters.authenticated) {
        if (this.$store.getters.clientType == "CLIENT") {
          this.$router.push("/c/home")
        }
        else if (this.$store.getters.clientType == "DOCTOR") {
          this.$router.push("/s/home");
        }
        else {
          this.$store.dispatch("logout");
        }
      }
      else {
        this.$store.dispatch("logout");
      }
    }
    else {
      this.$store.dispatch("logout");
    }
  },
  watch: {
    selected: {
      handler(value) {
        this.updateLocale(value);
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  }
};
</script>
