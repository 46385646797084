var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { staticClass: "w-100 grey lighten-4", attrs: { "fill-height": "" } },
    [
      _c(
        "main",
        { staticClass: "w-100 my-3 fill-height mx-auto" },
        [
          _c("v-container", { attrs: { fluid: "", "fill-height": "" } }, [
            _c(
              "section",
              {
                staticClass:
                  "w-100 d-flex align-items-center justify-content-center fill-height",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-sm-6 col-12 rounded-lg shadow-sm d-flex flex-column white fill-height",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "align-self-end" },
                      [
                        _c("v-select", {
                          staticClass: "rounded-pill fit f12",
                          attrs: {
                            items: _vm.languages,
                            solo: "",
                            dense: "",
                            "hide-details": "",
                          },
                          model: {
                            value: _vm.selected,
                            callback: function ($$v) {
                              _vm.selected = $$v
                            },
                            expression: "selected",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex align-items-center justify-content-center",
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/app/mindology.png"),
                            width: "40%",
                            "max-width": "150px",
                          },
                        }),
                      ]
                    ),
                    _c("div", { staticClass: "mt-2 text-center" }, [
                      _c(
                        "span",
                        { staticClass: "font-weight-bold text--black f16" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("It`s time to get to yourself!")) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "mt-3 text-center" }, [
                      _c(
                        "span",
                        {
                          staticClass:
                            "font-weight-normal grey--text text--darken-1 f15",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "In Mindology, the comprehensive platform of psychology"
                                )
                              ) +
                              " "
                          ),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "Train and practice regularly to improve your quality of life"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "ms-0 me-2 mt-auto" },
                      [
                        _c("v-checkbox", {
                          staticClass: "font-weight-light text--grey lighten-1",
                          scopedSlots: _vm._u([
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _c("span", { staticClass: "f13" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "Login or registration means accept"
                                          )
                                        ) +
                                        " "
                                    ),
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: "https://mindology.io/policy",
                                          target: "_blank",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "text--info" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("terms and conditions")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" " + _vm._s(_vm.$t("and")) + " "),
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: "https://mindology.io/privacy",
                                          target: "_blank",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "text-info" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("privacy policy")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("of Mindology.")) +
                                        " "
                                    ),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                          model: {
                            value: _vm.checkbox,
                            callback: function ($$v) {
                              _vm.checkbox = $$v
                            },
                            expression: "checkbox",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "mt-2 d-flex flex-row align-items-stretch",
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "flex-fill mx-2 13",
                            attrs: { color: "primary", large: "" },
                            on: {
                              click: function ($event) {
                                return _vm.loginUser()
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("Client login")) + " ")]
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "flex-fill mx-2 f13",
                            attrs: { color: "primary", large: "" },
                            on: {
                              click: function ($event) {
                                return _vm.loginDoctor()
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("Specialist login")) + " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "span",
                      {
                        staticClass:
                          "text-center d-block w-100 fw-bold f13 primary--text text--darken-3 mt-1",
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("Version")) + " " + _vm._s(_vm.version)
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }